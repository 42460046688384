@use 'variables';

// MOBILE APPS SECTION
.new-preview {
    padding: 3px;
    border: 3px solid variables.$black;
    border-radius: 25px;
}

section.mobileapps-box {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(36rem, 1fr));
    grid-gap: 4rem;

    .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0px 0px 20px 4px variables.$black;

        img {
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
        }
    }

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .card:hover {
        z-index: 1;
        transform: scale(1.04);
    }

    p {
        a {
            color: variables.$red;
            text-decoration: none;
        }

        a:hover {
            color: variables.$black;
            text-decoration: underline;
        }
    }

    .btn-primary {
        width: 100%;
        background-color: variables.$red;
        border: 0;
        border-radius: 1rem;
    }

    .btn-primary:hover {
        width: 100%;
        color: variables.$red;
        background-color: variables.$white;
        border: 1px solid variables.$red;
        border-radius: 1rem;
    }
}

// RESPONSIVENESS 
@media (max-width: 580px) {
    .new-preview {
        border-radius: 10px;
    }

    section.mobileapps-box {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
        grid-gap: 2rem;
    }
}