@use 'projects';
@use 'music';
@use 'books';
@use 'mobileapps';

@use 'variables';

* {
    box-sizing: border-box;
    transition: all 1s ease-in-out;
}

// Font Awesome + Google Fonts
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');

// Theme
.site-title {
    border-radius: 1rem;
    background: linear-gradient(25deg, variables.$white, transparent);
}

a.default-link,
.default-link {
    color: variables.$white;
    text-decoration: none;
    background-color: variables.$red;
    border: 0;
    border: 1px solid variables.$red;
    border-radius: 1rem;
}

a.default-link:hover,
.default-link:hover {
    color: variables.$black;
    color: variables.$red;
    background-color: variables.$white;
    border: 1px solid variables.$red;
    border-radius: 1rem;
}

a.default-link:focus,
.default-link:focus {
    color: variables.$black;
    // text-decoration: underline;
    // width: 100%;
    color: variables.$red;
    background-color: variables.$white;
    border: 1px solid variables.$red;
    border-radius: 1rem;
    box-shadow: none;
}

::selection {
    background-color: variables.$black;
    color: variables.$white;
    text-shadow: none;
}

body {
    width: auto;
    font-family: 'Josefin Sans', sans-serif;
    background-color: variables.$red;
}

#wrapper {
    background-color: variables.$red;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#main {
    margin: 1rem;
}

// HEADER 
header {
    background-color: variables.$red;
}

nav {
    color: variables.$white;

    a {
        color: variables.$white;

        &:hover {
            color: variables.$black;
        }
    }

    .navbar-toggler {
        i {
            color: variables.$white;
        }

        &:focus {
            text-decoration: none;
            outline: 0;
            box-shadow: none;
        }
    }

    div ul.social-icons {
        display: flex;
        flex-direction: inherit;
    }
}

// FOOTER
footer {
    margin: 1rem 0rem;
    background-color: variables.$red;
    color: variables.$white;
    display: flex;
    flex-direction: column;
    text-align: center;

    ul {
        justify-content: center;
        list-style: none;
        display: flex;
        padding: 0px;
    }

    ul li {
        padding: 0px 5px;
    }

    ul li a {
        color: variables.$white;
    }

    ul li a:hover {
        color: variables.$black;
    }
}

// HOME
.home {
    text-align: center;
    width: auto;
    color: variables.$white;
    padding: 50px;

    img {
        width: 250px;
        box-shadow: 0px 0px 20px 8px variables.$white;
        border-radius: 50px;
    }

    img:hover {
        transform: scale(0.8);
    }
}

// CONTACT
form.contact-box {

    input,
    textarea {
        border-radius: 1rem;
    }
}


// RESPONSIVENESS
@media (min-width: 1440px) {
    #wrapper {
        width: 1340px;
        margin: auto;
    }

    nav,
    #main {
        width: 1340px;
        margin: auto;
    }
}

@media (max-width: 992px) {
    nav {
        div ul.social-icons {
            li {
                padding: 0px 5px;
            }
        }
    }
}

@media (max-width: 580px) {
    a.default-link {
        width: 100%;
    }

    .site-title {
        border-radius: 1rem;
        background: linear-gradient(125deg, variables.$white, transparent);
        padding: 1rem !important;
    }
}