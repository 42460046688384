@use 'variables';

// BOOKS
section.books-box {

    article {
        background-color: variables.$glass-white;
        border-radius: 1rem;
        padding: 1rem;
    }

    .card-book-img {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            border-radius: 0.5rem;
            width: 200px;
        }
    }

    .card-body {
        h6 {
            font-style: italic;
        }

        p {
            text-align: justify;
        }
    }

    .books-contribution {
        display: flex;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        justify-content: unset;
        -ms-overflow-style: none;
        scrollbar-width: none;

        span {
            padding: 0.5rem;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }
}