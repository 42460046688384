@use 'variables';

// MUSIC 
section.music-box {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    grid-gap: 1rem;

    .title {
        width: 18rem;
        height: 18rem;
        margin: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .card {
        background-size: cover;
        width: 18rem;
        height: 18rem;
        margin: .7rem;
        border: 0;
        border-radius: 1rem;
        box-shadow: 0px 0px 20px 4px variables.$black;

        .card-img-overlay {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            padding: 0px;
        }

        .card-titles {
            line-height: .5rem;
            display: none;
            padding: 1rem;
        }
    }

    .card:hover {
        transform: scale(1.1);
        margin: .7rem;
        box-shadow: 0px 0px 20px 4px variables.$white;
        z-index: 1;

        .card-img-overlay {
            background: linear-gradient(360deg, black, transparent);
            border-radius: 1rem;
        }

        .card-titles {
            display: initial;
        }

        img {
            border-radius: 1rem;
            transform: scale(1.2);
            box-shadow: 0px 0px 20px 4px variables.$black;
        }
    }

    p {
        a {
            color: variables.$red;
            text-decoration: none;
        }

        a:hover {
            color: variables.$black;
            text-decoration: underline;
        }
    }

    .btn-primary {
        width: 100%;
        background-color: variables.$red;
        border: 0;
        border-radius: 1rem;
    }

    .btn-primary:hover {
        width: 100%;
        color: variables.$red;
        background-color: variables.$white;
        border: 1px solid variables.$red;
        border-radius: 1rem;
    }
}

section.music-socials {
    text-align: center;

    ul {
        justify-content: center;
        list-style: none;
        display: flex;
        padding: 0px;
    }

    ul li {
        padding: 0px 5px;
    }

    ul li a {
        color: variables.$white;
    }

    ul li a:hover {
        color: variables.$black;
    }
}

// RESPONSIVENESS 
@media (max-width: 580px) {
    section.music-box {

        .title {
            width: 18rem;
            height: 4rem;
            margin: .5rem;
        }

        .card {
            width: 20rem;
            height: 20rem;
        }
    }
}